import { INITIAL_IMPORT_FILTERS } from 'dashboards/import/constants';
import { IImportFilters } from 'dashboards/import/types';
import React, { ReactNode, createContext, useContext, useState } from 'react';

interface ImportContextType {
  filters: IImportFilters;
  setFilter: (
    name: keyof IImportFilters,
    value: boolean | string | string[] | number | undefined,
  ) => void;
  clearFilters: () => void;
}

const ImportContext = createContext<ImportContextType | undefined>(undefined);

export const useFilter = (): ImportContextType => {
  const context = useContext(ImportContext);
  if (!context) {
    throw new Error('useFilter must be used within a ImportProvider');
  }
  return context;
};

interface ImportProviderProps {
  children: ReactNode;
}

export const ImportProvider: React.FC<ImportProviderProps> = ({ children }) => {
  const [filters, setFilters] = useState<IImportFilters>(
    INITIAL_IMPORT_FILTERS,
  );

  const setFilter = (
    name: keyof IImportFilters,
    value?: boolean | string | string[] | number | undefined,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const clearFilters = () => {
    setFilters(INITIAL_IMPORT_FILTERS);
  };

  return (
    <ImportContext.Provider value={{ filters, setFilter, clearFilters }}>
      {children}
    </ImportContext.Provider>
  );
};
