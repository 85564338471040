import { Map, fromJS } from 'immutable';
import qs from 'querystringify';

import {
  STATUS_BAR_ERROR,
  STATUS_BAR_IN_PROGRESS,
  STATUS_BAR_REVIEW,
  STATUS_BAR_SUCCESS,
  STATUS_BAR_TIMEOUT,
} from 'components/ui/status-bar';
import { dates } from 'modules/io/export/constants';
import { createReducer } from 'utils/reducers';
import {
  addFilter,
  addOneOptionFilter,
  changeLimit,
  clearFilter,
  initPagination,
  nextPage,
  previousPage,
  receiveReducer,
  removeFilter,
  search,
  updateFilter,
} from 'utils/reducers/list';

import * as events from '../constants/events';

const LIMIT_STORAGE_KEY = 'exportListLimit';
const SORTING_STORAGE_KEY = 'exportListSorting';

function getStatusFilter() {
  return qs.parse(location.search).status
    ? {
        value: qs.parse(location.search).status,
        label: qs.parse(location.search).status,
      }
    : null;
}

function getFormatFilter() {
  return qs.parse(location.search).format
    ? {
        value: qs.parse(location.search).format,
        label: qs.parse(location.search).format,
      }
    : null;
}

function getOrganizationFilter() {
  return qs.parse(location.search).organization_id_in
    ? [
        {
          value: qs.parse(location.search).organization_id_in,
          label: qs.parse(location.search).organization_id_in,
        },
      ]
    : [];
}

function getIdInFilter() {
  return qs.parse(location.search).filter_ids_in
    ? [
        {
          value: qs.parse(location.search).filter_ids_in,
        },
      ]
    : [];
}

function getDatesFilter() {
  const datesFilter = [];
  if (qs.parse(location.search).start_date) {
    datesFilter.push({
      name: 'start_date',
      value: qs.parse(location.search).start_date,
      label: 'Date from',
    });
  }
  if (qs.parse(location.search).end_date) {
    datesFilter.push({
      name: 'end_date',
      value: qs.parse(location.search).end_date,
      label: 'Date to',
    });
  }
  return datesFilter;
}

const initialState = {
  search: qs.parse(location.search).q,
  list: [],
  isLoading: true,
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
  filters: {
    organizations: getOrganizationFilter(),
    format: getFormatFilter(),
    status: getStatusFilter(),
    filter_ids_in: getIdInFilter(),
    dates: getDatesFilter(),
  },
  filtersAvailable: fromJS({
    organizations: [],
    formats: [],
    statuses: [],
    filter_ids_in: [],
    dates: [],
  }),
  modal: {
    isOpen: false,
    isExportFileInProgress: false,
    currentExportFile: null,
    parameters: {
      organization_id: 7,
      format: 'xlsx',
      slice_file_size: 100,
      zip_all: true,
      filter_source_include: ['gtin', 'tags'],
      filter_gtins_in: ['03663215000073'],
    },
  },
  viewJsonModal: {
    isOpen: false,
    jsonData: { something: false },
  },
  potentialSupplierModal: {
    isPotentialSupplierOpen: false,
    isPotentialSupplierExportFileInProgress: false,
    currentPotentialSupplierExportFile: null,
    parameters: {
      organization_id: 5,
    },
  },
  gdsnModal: {
    isOpen: false,
  },
  references: Map(),
};

export default createReducer(initialState, {
  [events.RECEIVE_EXPORT_LIST]: (state, action) =>
    receiveReducer(state, action.payload),
  [events.CHANGE_LIMIT_EXPORT_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.NEXT_PAGE_EXPORT_LIST]: nextPage,
  [events.PREVIOUS_PAGE_EXPORT_LIST]: previousPage,
  [events.ADD_EXPORT_LIST_FILTER]: addFilter,
  [events.REMOVE_EXPORT_LIST_FILTER]: removeFilter,
  [events.UPDATE_EXPORT_LIST_FILTER]: updateFilter,
  [events.CLEAR_EXPORT_LIST_FILTER]: clearFilter,
  [events.ADD_ONE_EXPORT_LIST_FILTER]: addOneOptionFilter,
  [events.SEARCH_EXPORT_LIST]: search,
  [events.RECEIVE_EXPORT_FILE_REFERENCE_LIST]: (state, action) => {
    const newState = { ...state };
    newState.references = Map([
      ...newState.references,
      [action.payload.exportFileId, action.payload.references],
    ]);
    return { ...newState };
  },
  [events.EXPORTGDSNFILE_MODAL_OPEN]: (state) => {
    const newState = { ...state };
    newState.gdsnModal = {
      isOpen: true,
    };
    return newState;
  },
  [events.EXPORTGDSNFILE_MODAL_CLOSE]: (state) => {
    const newState = { ...state };
    newState.gdsnModal = {
      isOpen: false,
    };
    return newState;
  },
  [events.EXPORTFILE_MODAL_OPEN]: (state, action) => {
    const newState = { ...state };
    const parameters =
      action.parameters !== null
        ? action.parameters
        : initialState.modal.parameters;
    newState.modal = {
      isOpen: true,
      isExportFileInProgress: false,
      currentExportFile: null,
      parameters,
    };
    return newState;
  },
  [events.VIEW_JSON_MODAL_OPEN]: (state, action) => {
    const newState = { ...state };
    const jsonData =
      action.jsonData !== null
        ? action.jsonData
        : initialState.viewJsonModal.jsonData;
    newState.viewJsonModal = {
      isOpen: true,
      jsonData,
    };
    return newState;
  },
  [events.VIEW_JSON_MODAL_CLOSE]: (state) => {
    const newState = { ...state };
    newState.viewJsonModal = {
      isOpen: false,
    };
    return newState;
  },
  [events.EXPORT_LIST_IS_LOADING]: (state) => {
    const newState = { ...state };
    newState.isLoading = true;
    return newState;
  },
  [events.RECEIVE_EXPORT_LIST_FILTER]: (state, action) => {
    const newState = { ...state };
    const qsv = qs.parse(location.search);
    newState.filtersAvailable = fromJS({
      ...action.filters,
      dates,
      statuses: [
        {
          label: 'SUCCESS',
          value: 'SUCCESS',
          statusBarType: STATUS_BAR_SUCCESS,
        },
        {
          label: 'IN_PROGRESS',
          value: 'IN_PROGRESS',
          statusBarType: STATUS_BAR_IN_PROGRESS,
        },
        {
          label: 'RETRY',
          value: 'RETRY',
          statusBarType: STATUS_BAR_TIMEOUT,
        },
        { label: 'REVIEW', value: 'REVIEW', statusBarType: STATUS_BAR_REVIEW },
        { label: 'ERROR', value: 'ERROR', statusBarType: STATUS_BAR_ERROR },
      ],
    });

    if (qsv.filter_ids_in) {
      const filter_ids_in = {
        label: `Show only ${qsv.filter_ids}`,
        value: qsv.filter_ids,
        checked: true,
      };
      newState.filters = {
        ...newState.filters,
        filter_ids_in,
      };
    } else {
      const filter_ids_in = [];
      newState.filters = {
        ...newState.filters,
        filter_ids_in,
      };
    }
    if (qsv.organization_id_in) {
      const organizations = qsv.organization_id_in
        .split(',')
        .map((organizationID) => {
          const selectedOrganization = newState.filtersAvailable
            .get('organizations')
            .find((o) => o.get('id') === parseInt(organizationID, 10));
          if (selectedOrganization) {
            return {
              checked: false,
              label: selectedOrganization.get('nameLegal'),
              value: selectedOrganization.get('id'),
            };
          }
          return null;
        });
      newState.filters = {
        ...newState.filters,
        organizations,
      };
    }
    return newState;
  },
  [events.EXPORTFILE_MODAL_CLOSE]: (state) => {
    const newState = { ...state };
    newState.modal = initialState.modal;
    return newState;
  },
  [events.CURRENT_EXPORTFILE_UPDATE]: (state, action) => {
    const newState = { ...state };
    newState.modal.currentExportFile = action.exportFile;
    newState.modal.isExportFileInProgress = action.isExportFileInProgress;
    return newState;
  },

  [events.EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_CLOSE]: (state) => {
    const newState = { ...state };
    newState.potentialSupplierModal = initialState.potentialSupplierModal;
    return newState;
  },

  [events.EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_OPEN]: (state, action) => {
    const newState = { ...state };
    const parameters =
      action.parameters !== null
        ? action.parameters
        : initialState.potentialSupplierModal.parameters;
    newState.potentialSupplierModal = {
      isPotentialSupplierOpen: true,
      isPotentialSupplierExportFileInProgress: false,
      currentPotentialSupplierExportFile: null,
      parameters,
    };
    return newState;
  },
  [events.CURRENT_POTENTIAL_SUPPLIER_EXPORTFILE_UPDATE]: (state, action) => {
    const newState = { ...state };
    newState.potentialSupplierModal.currentPotentialSupplierExportFile =
      action.supplierExportFile;
    newState.potentialSupplierModal.isPotentialSupplierExportFileInProgress =
      action.isPotentialSupplierExportFileInProgress;
    return newState;
  },
});
