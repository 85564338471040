import React, { useEffect, useRef, useState } from 'react';

import './SingleSelect.scss';

interface Option {
  id: string;
  label: string;
}

interface SingleSelectProps {
  options: Option[];
  placeholder?: string;
  value?: string | null;
  onChange: (option: string | undefined) => void;
}

export const SingleSelect: React.FC<SingleSelectProps> = ({
  options,
  placeholder = 'Select an option...',
  value = null,
  onChange = () => {},
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(value);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (value: string) => {
    const option = options.find((option) => option.id === value)!;
    setSelectedId(option.id);
    setInputValue(option.label);
    onChange(option.id);
    setIsOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setSelectedId(null);
    onChange(undefined);
    setIsOpen(true);
  };

  const handleClearSelection = () => {
    setSelectedId(null);
    setInputValue('');
    onChange(undefined);
    setIsOpen(false);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase()),
  );

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <div className="UISingleSelect" ref={dropdownRef}>
      <div className="UISingleSelect__Dropdown__Container">
        <input
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onClick={() => setIsOpen(true)}
          className="UISingleSelect__Input"
        />
        {selectedId && (
          <span
            className="UISingleSelect__Clear"
            onClick={handleClearSelection}
          >
            &times;
          </span>
        )}
        {isOpen && (
          <ul className="UISingleSelect__Dropdown">
            {filteredOptions.map((option) => (
              <li key={option.id} onClick={() => handleSelect(option.id)}>
                {option.label}
              </li>
            ))}
            {filteredOptions.length === 0 && (
              <li className="UISingleSelect__Empty">No options available</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
