import classnames from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Text } from '@alkem/react-ui-inputs';

import { isValidJSON } from 'utils';

export default class ReferentialEntity extends PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    source: PropTypes.object.isRequired,
    edited: PropTypes.object.isRequired,
    isNewlyCreated: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isValidJSON: isValidJSON(
        JSON.stringify(props.source.get('data'), null, 2),
      ),
    };
  }

  onUpdateCode = (event) =>
    this.props.onUpdate(this.props.index, 'code', event.target.value);

  onUpdateLabel = (event) =>
    this.props.onUpdate(this.props.index, 'label', event.target.value);

  onUpdateDescription = (event) =>
    this.props.onUpdate(this.props.index, 'description', event.target.value);

  onUpdateRank = (event) =>
    this.props.onUpdate(this.props.index, 'rank', event.target.value);

  onUpdateData = (event) => {
    const value = event.target.value;
    this.setState({ isValidJSON: isValidJSON(value) });
    if (isValidJSON(value)) {
      this.props.onUpdate(this.props.index, 'data', JSON.parse(value) || Map());
    }
  };

  onDelete = () =>
    this.props.onDelete(this.props.index, this.props.isNewlyCreated);

  render() {
    const { index, source, edited, isNewlyCreated, readOnly } = this.props;

    const codeClasses = classnames({
      ReferentialEntity__code: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        edited.get('code') !== source.get('code'),
      'ReferentialEntity__input--invalid': !edited.get('code'),
    });
    const labelClasses = classnames({
      ReferentialEntity__label: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        edited.get('label') !== source.get('label'),
    });
    const descriptionClasses = classnames({
      ReferentialEntity__description: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        edited.get('description') !== source.get('description'),
    });
    const rankClasses = classnames({
      ReferentialEntity__rank: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        edited.get('rank') !== source.get('rank'),
    });

    const data = JSON.stringify(edited.get('data'), null, 2);
    const dataClasses = classnames({
      ReferentialEntity__data: true,
      'ReferentialEntity__data--dirty':
        data !== JSON.stringify(source.get('data'), null, 2),
      'ReferentialEntity__data--invalid': !this.state.isValidJSON,
    });

    return (
      <tr id={`referential-entity-${index}`} className="ReferentialEntity">
        <td className={codeClasses}>
          <Text
            id={`referential-entity-${index}-code`}
            value={edited.get('code') || ''}
            placeholder="Code"
            onChange={this.onUpdateCode}
            disabled={!isNewlyCreated || readOnly}
          />
        </td>
        <td className={labelClasses}>
          <Text
            id={`referential-entity-${index}-label`}
            value={edited.get('label') || ''}
            placeholder="Label"
            onChange={this.onUpdateLabel}
            disabled={readOnly}
          />
        </td>
        <td className={descriptionClasses}>
          <Text
            id={`referential-entity-${index}-decription`}
            value={edited.get('description') || ''}
            placeholder="Description"
            onChange={this.onUpdateDescription}
            disabled={readOnly}
          />
        </td>
        <td className={rankClasses}>
          <Text
            id={`referential-entity-${index}-rank`}
            value={edited.get('rank') || ''}
            placeholder="Rank"
            type="number"
            onChange={this.onUpdateRank}
            disabled={readOnly}
          />
        </td>
        <td className={dataClasses}>
          <textarea
            name={`referential-entity-${index}-data`}
            defaultValue={data}
            className={dataClasses}
            onChange={this.onUpdateData}
            rows={6}
            readOnly={readOnly}
          />
        </td>
        {!readOnly && (
          <td className="ReferentialEntity__delete">
            {(isNewlyCreated || window.env === 'ppr') && (
              <i className="mdi mdi-delete" onClick={this.onDelete} />
            )}
          </td>
        )}
      </tr>
    );
  }
}
