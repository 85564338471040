import { IColor } from 'dashboards/shared/types';

import { IImportStatus, IImportSupportStatus } from '../types';

export const getImportSupportStatusChipColor = (
  status: IImportSupportStatus,
) => {
  switch (status) {
    case IImportSupportStatus.DONE:
      return IColor.TEAL_DARK;
    case IImportSupportStatus.IN_PROGRESS:
      return IColor.AMBER;
    case IImportSupportStatus.TO_DO:
      return IColor.RED;
  }
};

export const getImportStatusChipColor = (status: IImportStatus) => {
  switch (status) {
    case IImportStatus.SUCCESS:
      return IColor.TEAL_DARK;
    case IImportStatus.IN_PROGRESS:
      return IColor.AMBER;
    case IImportStatus.CONTAINS_ERRORS:
      return IColor.INDIGO_DARK;
    case IImportStatus.ERROR:
      return IColor.RED;
    case IImportStatus.TIMEOUT:
      return IColor.DEEP_PURPLE_DARK;
    case IImportStatus.EMPTY:
      return IColor.PURPLE;
  }
};
