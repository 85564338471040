import { Chip } from 'dashboards/shared/components';
import { IColor } from 'dashboards/shared/types';
import { useEffect, useRef, useState } from 'react';

import './MultiSelect.scss';

export interface MultiSelectOption {
  id: string;
  label: string;
}

interface MultiSelectProps {
  options: MultiSelectOption[];
  placeholder?: string;
  value?: string[];
  onChange?: (options: string[] | undefined) => void;
}

export const MultiSelect = ({
  options,
  placeholder = 'Select one or more options...',
  value = [],
  onChange = () => {},
}: MultiSelectProps) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(value);
  const [filterText, setFilterText] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (optionId: string) => {
    if (!selectedIds.some((id) => id === optionId)) {
      const options = [...selectedIds, optionId];
      setSelectedIds(options);
      onChange(options);
    }
  };

  const handleRemove = (optionId: string) => {
    const options = selectedIds.filter((id) => id !== optionId);
    setSelectedIds(options);
    onChange(options.length > 0 ? options : undefined);
  };

  const handleClearSelection = () => {
    setSelectedIds([]);
    onChange(undefined);
    setFilterText('');
    setIsOpen(false);
  };

  const filteredOptions = options.filter(
    (option) =>
      option.label.toLowerCase().includes(filterText.toLowerCase()) &&
      !selectedIds.some((id) => id === option.id),
  );

  const placeholderText =
    selectedIds.length > 0
      ? `${selectedIds.length} option${selectedIds.length > 1 ? 's' : ''} selected`
      : placeholder;

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <div className="UIMultiSelect" ref={dropdownRef}>
      <div className="UIMultiSelect__Dropdown__Container">
        <input
          type="text"
          placeholder={placeholderText}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          onClick={() => setIsOpen(!isOpen)}
          className="UIMultiSelect__Input"
        />
        {selectedIds.length > 0 && (
          <span className="UIMultiSelect__Clear" onClick={handleClearSelection}>
            &times;
          </span>
        )}
        {isOpen && (
          <ul className="UIMultiSelect__Dropdown">
            {filteredOptions.map((option) => (
              <li key={option.id} onClick={() => handleSelect(option.id)}>
                {option.label}
              </li>
            ))}
            {filteredOptions.length === 0 && (
              <li className="UIMultiSelect__Dropdown__Empty">
                No options available
              </li>
            )}
          </ul>
        )}
      </div>
      {selectedIds.length > 0 && (
        <div className="MultiSelect__Tags">
          {selectedIds.map((id) => {
            const option = options.find((option) => option.id === id)!;
            return (
              <Chip
                key={option.id}
                label={option.label}
                color={IColor.PRIMARY}
                onClick={() => handleRemove(id)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
