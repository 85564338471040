import { useQuery } from '@tanstack/react-query';

import coreApi from 'resources/coreBaseApi';

interface IAPIImportFilters {
  data: {
    data: {
      which: string[];
      organization: {
        id: string;
        nameLegal: string;
      }[];
    };
  };
}

const fetchImportFilters = async (): Promise<IAPIImportFilters> => {
  return await coreApi.get('/etl/v1/products/import/filters?alk=1');
};

export const useGetImportFilters = () => {
  return useQuery<IAPIImportFilters, Error>({
    queryKey: ['importFilters'],
    queryFn: fetchImportFilters,
    retry: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
