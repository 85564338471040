import { IColor } from 'dashboards/shared/types';

import './Banner.scss';

export enum BannerType {
  WARNING = IColor.PRIMARY,
}

interface BannerProps {
  type: BannerType;
  children: React.ReactNode;
}

export const Banner = ({ type, children }: BannerProps) => {
  return (
    <div className="UIBanner" style={{ backgroundColor: type }}>
      {children}
    </div>
  );
};
