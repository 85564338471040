import { IImportFilters } from 'dashboards/import/types';

export const IMPORT_PAGINATION = [10, 20, 50];

export const INITIAL_IMPORT_FILTERS: IImportFilters = {
  alk: 1,
  offset: 0,
  limit: IMPORT_PAGINATION[0],
  filter_empty_out: false,
  filter_unknown_gln: true,
  filter_user_ids_out: true,
};
