import { IImportReport } from 'dashboards/import/hooks';
import {
  getImportStatusChipColor,
  getImportSupportStatusChipColor,
} from 'dashboards/import/utils';
import { Chip } from 'dashboards/shared/components';
import { IColor } from 'dashboards/shared/types';
import { MRT_ColumnDef } from 'material-react-table';

export const IMPORT_TABLE_COLUMNS: MRT_ColumnDef<IImportReport>[] = [
  {
    header: 'Organizations',
    Cell: ({ row }) => {
      const { targetOrganizations = [] } = row.original;
      const { id, username } = row.original.user || {};
      return (
        <div className="UIImportTable__Columns">
          {targetOrganizations.map(({ id, nameLegal }) => (
            <span key={id}>
              {nameLegal} ({id})
            </span>
          ))}
          <span className="UIImportTable__Columns__User">
            {username} ({id})
          </span>
        </div>
      );
    },
  },
  {
    header: 'Format',
    Cell: ({ row }) => {
      const { spider } = row.original;
      return <Chip color={IColor.INDIGO} label={spider} />;
    },
  },
  {
    header: 'Items',
    Cell: ({ row }) => {
      const {
        counts: { total, success },
        duration,
      } = row.original || {};
      const timePerImport = Math.round(duration / total);
      return (
        <div className="UIImportTable__Columns">
          <span>
            {success} / {total}
          </span>
          <span>({duration}s)</span>
          {total > 0 && (
            <span className="UIImportTable__Columns__Time">
              {timePerImport}s/item
            </span>
          )}
        </div>
      );
    },
  },
  {
    header: 'Status',
    Cell: ({ row }) => {
      const { status } = row.original;
      return <Chip color={getImportStatusChipColor(status)} label={status} />;
    },
  },
  {
    header: 'Support Status',
    Cell: ({ row }) => {
      const {
        support: { status: supportStatus },
      } = row.original;
      return (
        <Chip
          color={getImportSupportStatusChipColor(supportStatus)}
          label={supportStatus}
        />
      );
    },
  },
  {
    accessorKey: 'createdAt',
    header: 'Created At',
    Cell: ({ row }) => {
      const { createdAt } = row.original;
      const date = createdAt.split('T')[0];
      const time = createdAt.split('T')[1];
      return (
        <div className="UIImportTable__Columns">
          <span>{date}</span>
          <span className="UIImportTable__Columns__Time">{time}</span>
        </div>
      );
    },
  },
];
