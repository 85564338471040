import classNames from 'classnames';
import { useState } from 'react';

import './Switch.scss';

type SwitchProps = {
  label?: string;
  value?: boolean;
  onChange?: (state: boolean) => void;
};

export const Switch = ({
  label,
  value = false,
  onChange = () => {},
}: SwitchProps) => {
  const [isOn, setIsOn] = useState(value);

  const handleToggle = () => {
    setIsOn(!isOn);
    onChange(!isOn);
  };

  return (
    <div className="UISwitch">
      <div
        className={classNames('UISwitch__Toggle', { Switch__Toggle__On: isOn })}
        onClick={handleToggle}
      >
        <div
          className={classNames('UISwitch__Thumb', { Switch__Thumb__On: isOn })}
        />
      </div>
      {label && <span className="UISwitch__Label">{label}</span>}
    </div>
  );
};
