import { useQuery } from '@tanstack/react-query';
import qs from 'querystringify';

import coreApi from 'resources/coreBaseApi';

import { IImportFilters, IImportStatus, IImportSupportStatus } from '../types';

export interface IImportReport {
  id: number;
  counts: {
    total: number;
    success: number;
  };
  createdAt: string;
  duration: number;
  spider: string;
  status: IImportStatus;
  support: {
    status: IImportSupportStatus;
  };
  targetOrganizations: {
    id: string;
    nameLegal: string;
  }[];
  user: {
    id: string;
    username: string;
  };
}

interface IAPIImportReports {
  data: {
    data: IImportReport[];
    totalResults: number;
  };
}

const fetchImportReports = async (
  filters: IImportFilters,
): Promise<IAPIImportReports> => {
  const params = { ...filters };
  if (filters.filter_user_ids_out) {
    params.filter_user_ids_out = 79 as unknown as boolean;
  } else {
    params.filter_user_ids_out = undefined;
  }
  return await coreApi.get(
    '/etl/v1/products/import/report' + qs.stringify(params, true),
  );
};

export const useGetImportReports = (filters: IImportFilters) => {
  return useQuery<IAPIImportReports, Error>({
    queryKey: ['importReports', filters],
    queryFn: () => fetchImportReports(filters),
    retry: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
