import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Button } from '@alkem/react-ui-button';
import { Application } from '@alkem/sdk-dashboard';

import { applicationDashboardAccessPolicy } from 'access-policies';
import { hasAccess } from 'modules/access-policy/common/utils';
import { selectPermissions } from 'modules/auth/reducer';
import { User, UserPermissions } from 'modules/auth/types';
import { selectUser } from 'reducers/user';
import { separateActions } from 'utils/redux';
import { getUserType } from 'utils/user';

import {
  fetchApplications,
  showMoreApplications,
  updateApplication,
} from '../../actions/applications';
import {
  hasApplicationsChanges,
  selectApplicationsChanges,
  selectApplicationsDict,
  selectApplicationsHiddenList,
  selectApplicationsVisibleList,
  selectIsFetchingApplications,
  selectShowMoreApplications,
} from '../../selectors/applications';

import './index.scss';
import OrganizationApplicationItem from './item';
import OrganizationApplicationItemsHeader from './items-header';

interface Props {
  actions: {
    fetchApplications: () => void;
    showMoreApplications: () => void;
    updateApplication: (payload: {
      applicationId: number;
      isEnabled: boolean;
    }) => void;
  };
  applicationsChanges: { [key: number]: boolean | undefined };
  applicationsDict: { [key: number]: Application };
  hasChanges: boolean;
  isReadOnly: boolean;
  hiddenApplications: number[];
  isLoading: boolean;
  showMore: boolean;
  visibleApplications: number[];
  user: User;
  userPermissions: UserPermissions;
}

const mapStateToProps = createStructuredSelector({
  applicationsChanges: selectApplicationsChanges,
  applicationsDict: selectApplicationsDict,
  hasChanges: hasApplicationsChanges,
  hiddenApplications: selectApplicationsHiddenList,
  isLoading: selectIsFetchingApplications,
  showMore: selectShowMoreApplications,
  visibleApplications: selectApplicationsVisibleList,
  user: selectUser,
  userPermissions: selectPermissions,
});

const mapDispatchToProps = {
  fetchApplications,
  showMoreApplications,
  updateApplication,
};

function InternalOrganizationApplications({
  actions,
  applicationsChanges,
  applicationsDict,
  hasChanges,
  hiddenApplications,
  showMore,
  visibleApplications,
  user,
  userPermissions,
  isReadOnly,
}: Props) {
  useEffect(() => {
    actions.fetchApplications();
  }, [actions]);

  const onChangeApplication = useCallback(
    (applicationId: number, isEnabled: boolean) => {
      actions.updateApplication({ applicationId, isEnabled });
    },
    [actions],
  );

  const onShowMore = useCallback(() => {
    actions.showMoreApplications();
  }, [actions]);

  const hasReadAccess = useMemo(
    () =>
      hasAccess(
        getUserType(user),
        userPermissions,
        applicationDashboardAccessPolicy,
        'read',
      ),
    [user, userPermissions],
  );
  const hasWriteAccess = !isReadOnly;

  if (!hasReadAccess && !hasWriteAccess) {
    return null;
  }

  const readOnly = !hasWriteAccess;
  let lastTypeVisible: number;
  let lastTypeHidden: number;

  return (
    <div className="OrganizationApplications OrganizationPageBlock">
      <div className="OrganizationPageBlock__header">
        <h2>Applications</h2>
        <span className="OrganizationPageBlock__edited">
          {hasChanges && (
            <span>
              <i className="mdi mdi-alert" />
              Edited
            </span>
          )}
        </span>
      </div>
      <ul className="OrganizationApplications__list">
        {visibleApplications.map((appId) => {
          const app = applicationsDict[appId];
          const withType = app.type !== lastTypeVisible;
          lastTypeVisible = app.type;
          return (
            <React.Fragment key={appId}>
              {withType && (
                <OrganizationApplicationItemsHeader application={app} />
              )}
              <OrganizationApplicationItem
                application={app}
                change={applicationsChanges[appId]}
                readOnly={readOnly}
                onChange={onChangeApplication}
              />
            </React.Fragment>
          );
        })}
      </ul>
      <Button
        primary
        className="OrganizationApplications__showMore"
        onClick={onShowMore}
      >
        {showMore ? (
          <>
            <i className="mdi mdi-chevron-down" />
            <span>Show less</span>
            <i className="mdi mdi-chevron-down" />
          </>
        ) : (
          <>
            <i className="mdi mdi-chevron-right" />
            <span>Show more</span>
            <i className="mdi mdi-chevron-left" />
          </>
        )}
      </Button>
      {showMore && (
        <ul className="OrganizationApplications__list">
          {hiddenApplications.map((appId) => {
            const app = applicationsDict[appId];
            const withType = app.type !== lastTypeHidden;
            lastTypeHidden = app.type;
            return (
              <React.Fragment key={appId}>
                {withType && (
                  <OrganizationApplicationItemsHeader application={app} />
                )}
                <OrganizationApplicationItem
                  application={applicationsDict[appId]}
                  change={applicationsChanges[appId]}
                  readOnly={isReadOnly}
                  onChange={onChangeApplication}
                />
              </React.Fragment>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export const OrganizationApplications: any = connect(
  mapStateToProps as any,
  mapDispatchToProps,
  separateActions,
)(memo(InternalOrganizationApplications));

export default OrganizationApplications;
