import { IMPORT_PAGINATION } from 'dashboards/import/constants';
import { useGetImportReports } from 'dashboards/import/hooks';
import { Button } from 'dashboards/shared/components';
import {
  MRT_PaginationState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useState } from 'react';

import { useFilter } from '../provider';

import { IMPORT_TABLE_COLUMNS } from './ImportTable.columns';
import './ImportTable.scss';

export const ImportTable = () => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { filters } = useFilter();

  const {
    isLoading,
    data: { data: { data: reports = [], totalResults = 0 } = {} } = {},
  } = useGetImportReports({
    ...filters,
    offset: (pagination.pageIndex + 1) * pagination.pageSize,
    limit: pagination.pageSize,
  });

  const table = useMaterialReactTable({
    columns: IMPORT_TABLE_COLUMNS,
    data: reports,
    state: {
      isLoading,
      pagination,
    },
    muiPaginationProps: {
      rowsPerPageOptions: IMPORT_PAGINATION,
    },
    layoutMode: 'grid',
    rowCount: totalResults,
    manualPagination: true,
    onPaginationChange: setPagination,
    enableKeyboardShortcuts: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: {
        height: 'calc(100vh - 348px)',
        '::-webkit-scrollbar': { display: 'none' },
      },
    },
    muiTablePaperProps: {
      sx: { boxShadow: 'none', border: '1px solid #E0E0E0' },
    },
    muiTableBodyRowProps: {
      hover: false,
      sx: {
        ':last-child': {
          borderBottom: 'unset',
        },
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: 'white',
        backgroundColor: '#166a83',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        borderTop: '1px solid #E0E0E0',
        boxShadow: 'none',
      },
    },
    muiTableHeadProps: {
      sx: {
        border: 'unset',
        boxShadow: 'none',
      },
    },
  });

  return (
    <div className="UIImportTable">
      <Button label="New Import" onClick={() => {}} />
      <MaterialReactTable table={table} />
    </div>
  );
};
