export interface IImportFilters {
  alk: number;
  filter_organization_ids?: number[];
  filter_user_ids_out?: boolean;
  filter_unknown_gln?: boolean;
  filter_empty_out?: boolean;
  filter_status?: string;
  filter_spiders?: string[];
  filter_support_status_in?: string;
  offset: number;
  limit: number;
}

export enum IImportStatus {
  SUCCESS = 'SUCCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  CONTAINS_ERRORS = 'CONTAINS_ERRORS',
  ERROR = 'ERROR',
  TIMEOUT = 'TIMEOUT',
  EMPTY = 'EMPTY',
}

export enum IImportSupportStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}
