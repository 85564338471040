import { ImportListPage } from 'dashboards/import/pages';
import { Suspense, lazy, memo, useEffect, useLayoutEffect } from 'react';
import {
  Link,
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { Spinner } from '@alkem/react-ui-spinner';
import { Authentication } from '@alkem/sdk-dashboard';

import {
  accessPolicyDashboardAccessPolicy,
  applicationDashboardAccessPolicy,
  displayGroupDashboardAccessPolicy,
  edaEventCapturesAccessPolicy,
  exchangeDashboardAccessPolicy,
  fieldDashboardAccessPolicy,
  gdsnDasshboardAccessPolicy,
  gdsnExportMappingsDashboardAccessPolicy,
  gdsnImportMappingsDashboardAccessPolicy,
  gdsnRciDashboardAccessPolicy,
  glnDashboardAccessPolicy,
  importExportDashboardAccessPolicy,
  indexerDashboardAccessPolicy,
  kindDashboardAccessPolicy,
  kronosDashboardAccessPolicy,
  organizationDashboardAccessPolicy,
  organizationGroupDashboardAccessPolicy,
  organizationGroupFieldDashboardAccessPolicy,
  productImportDashboardAccessPolicy,
  productMaintenanceDashboardAccessPolicy,
  rabbitmqDashboardAccessPolicy,
  referentialDashboardAccessPolicy,
  retailerFieldsDashboardAccessPolicy,
  settingsDashboardAccessPolicy,
  transactionDashboardAccessPolicy,
  translationDashboardAccessPolicy,
  userDashboardAccessPolicy,
  validationDashboardAccessPolicy,
} from 'access-policies';
import * as ROUTES from 'constants/routes';
import { logout } from 'constants/routes';
import ApplicationsDashboardView from 'modules/applications/views';
import Route from 'modules/auth/components/ProtectedRoute';
import LoginView from 'modules/auth/views/LoginView';
import LogoutView from 'modules/auth/views/LogoutView';
import BrandClaimDashboard from 'modules/brand-claim';
import DiscoverServives from 'modules/discover-services';
import DisplayGroupView from 'modules/display-groups/views';
import {
  EdaCapturedEventsGraphModule,
  EdaCapturedEventsModule,
  EdaEventCapturesModule,
} from 'modules/eda/event-capture';
import EdaRmqExplorer from 'modules/eda/rmq-explorer';
import { EdaRmqReplayer } from 'modules/eda/rmq-replayer';
import EdaRmqEventViewer from 'modules/eda/rmq-viewer';
import FieldSuggestionDashboard from 'modules/field-suggestion';
import FieldsPage from 'modules/fields';
import GLNClaimDashboard from 'modules/gln-claim';
import HomeView from 'modules/home';
import PumpConfiguration from 'modules/indexer/pump';
import IndexingQueue from 'modules/indexer/queue';
import EndpointDashboardView from 'modules/io/endpoint';
import ExportDashboardView from 'modules/io/export';
import GDSNCIDashboardView from 'modules/io/gdsn/ci';
import GDSNCISDashboardView from 'modules/io/gdsn/cis';
import GDSNExportMappingsView from 'modules/io/gdsn/exports';
import GDSNImportMappingsView from 'modules/io/gdsn/imports';
import GDSNPartyDashboardView from 'modules/io/gdsn/party';
import GDSNPSRDashboardView from 'modules/io/gdsn/psr';
import GDSNRCIDashboardView from 'modules/io/gdsn/rci';
import GDSNRelationDashboardView from 'modules/io/gdsn/relation';
import ImportDashboardView from 'modules/io/import';
import ImportGenericDashboardView from 'modules/io/importgeneric';
import MapperAFSDashboardView from 'modules/io/mapper/afs';
import { ProductImportDashboardModule } from 'modules/io/product-import';
import RevisionList from 'modules/kronos/revision';
import { OpenAPIModule } from 'modules/openapi';
import OrganizationFieldGroupsModule from 'modules/organization-group-field';
import OrganizationGroups from 'modules/organization-groups';
import OrganizationDashboardView from 'modules/organization-list';
import OrganizationPageDashboardView from 'modules/organization-page';
import OrganizationUsesFieldsView from 'modules/organization-uses-fields/views';
import ProductMaintenance from 'modules/product-maintenance';
import { ProductReview } from 'modules/product-review';
import ProductWorkflowPlayground from 'modules/product-workflow-playground';
import Rabbitmq from 'modules/rabbitmq';
import ReferentialPicturesDashboard from 'modules/referential-pictures';
import ReferentialsManager from 'modules/referentials';
import ReportingHandlingView from 'modules/reporting-handling';
import RetailerProductPictureComparator from 'modules/retailer-picture-comparator/views';
import TransactionDashboardView from 'modules/transaction';
import TransactionExplorerDashboardView from 'modules/transaction-explorer';
import TranslationDashboardView from 'modules/translation';
import UserDashboardView from 'modules/user-list';
import ValidationDashboard from 'modules/validation-dashboard';
import { NAVIGATE_EVENT } from 'utils/location';
import KindDashboardView from 'views/KindDashboardView/KindDashboardView';

const SettingList = lazy(async () => {
  const { List } = await import('modules/settings');
  return { default: List };
});
const SettingEdit = lazy(async () => {
  const { Edit } = await import('modules/settings');
  return { default: Edit };
});
const AccessPolicy = lazy(async () => {
  const { List } = await import('modules/access-policy');
  return { default: List };
});
const AccessPolicyEdit = lazy(async () => {
  const { Edit } = await import('modules/access-policy');
  return { default: Edit };
});

const routes = [
  {
    path: ROUTES.home,
    element: <Route component={HomeView} bypassAccessPolicy />,
  },
  {
    path: ROUTES.login,
    element: <Route component={LoginView} isPublic />,
  },
  {
    path: ROUTES.logout,
    element: <Route component={LogoutView} isPublic />,
  },
  {
    path: ROUTES.kinds,
    element: (
      <Route
        component={KindDashboardView}
        accessPolicy={kindDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.transactionExplorer,
    element: (
      <Route
        component={TransactionExplorerDashboardView}
        accessPolicy={transactionDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.transaction,
    element: (
      <Route
        component={TransactionDashboardView}
        accessPolicy={transactionDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.referentials,
    element: (
      <Route
        component={ReferentialsManager}
        accessPolicy={referentialDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.organizationUsesFields,
    element: (
      <Route
        component={OrganizationUsesFieldsView}
        accessPolicy={retailerFieldsDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.organization,
    element: (
      <Route
        component={OrganizationPageDashboardView}
        accessPolicy={organizationDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.organizations,
    element: (
      <Route
        component={OrganizationDashboardView}
        accessPolicy={organizationDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.reportingHandling,
    element: <Route component={ReportingHandlingView} />,
  },
  {
    path: ROUTES.applications,
    element: (
      <Route
        component={ApplicationsDashboardView}
        accessPolicy={applicationDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.displayGroups,
    element: (
      <Route
        component={DisplayGroupView}
        accessPolicy={displayGroupDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioImportGeneric,
    element: (
      <Route
        component={ImportGenericDashboardView}
        accessPolicy={importExportDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioImport,
    element: (
      <Route
        component={ImportDashboardView}
        accessPolicy={importExportDashboardAccessPolicy}
      />
    ),
  },
  {
    path: `${ROUTES.ioImport}-refactor`,
    element: (
      <Route
        component={ImportListPage}
        accessPolicy={importExportDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioProductImport,
    element: (
      <Route
        component={ProductImportDashboardModule}
        accessPolicy={productImportDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioExport,
    element: (
      <Route
        component={ExportDashboardView}
        accessPolicy={importExportDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioEndpoints,
    element: (
      <Route
        component={EndpointDashboardView}
        accessPolicy={exchangeDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioGdsnCi,
    element: (
      <Route
        component={GDSNCIDashboardView}
        accessPolicy={gdsnDasshboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioGdsnCis,
    element: (
      <Route
        component={GDSNCISDashboardView}
        accessPolicy={gdsnDasshboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioGdsnParty,
    element: <Route component={GDSNPartyDashboardView} />,
  },
  {
    path: ROUTES.ioGdsnRci,
    element: (
      <Route
        component={GDSNRCIDashboardView}
        accessPolicy={gdsnRciDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioGdsnPsr,
    element: <Route component={GDSNPSRDashboardView} />,
  },
  {
    path: ROUTES.ioGdsnRelation,
    element: <Route component={GDSNRelationDashboardView} />,
  },
  {
    path: ROUTES.ioGdsnImports,
    element: (
      <Route
        component={GDSNImportMappingsView}
        accessPolicy={gdsnImportMappingsDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.ioGdsnExports,
    element: (
      <Route
        component={GDSNExportMappingsView}
        accessPolicy={gdsnExportMappingsDashboardAccessPolicy}
      />
    ),
  },
  {
    path: `${ROUTES.ioMapperAfs}/:type?/:id?`,
    element: <Route component={MapperAFSDashboardView} />,
  },
  {
    path: ROUTES.servicesOpenapi,
    element: <Route component={OpenAPIModule} />,
  },
  {
    path: 'validation/dashboard',
    element: <Navigate to={ROUTES.validationDashboard} />,
  },
  {
    path: `${ROUTES.validationDashboard}/*`,
    element: (
      <Route
        component={ValidationDashboard}
        accessPolicy={validationDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.users,
    element: (
      <Route
        component={UserDashboardView}
        accessPolicy={userDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.pictureComparator,
    element: <Route component={RetailerProductPictureComparator} />,
  },
  {
    path: ROUTES.fieldSuggestion,
    element: <Route component={FieldSuggestionDashboard} />,
  },
  {
    path: ROUTES.productWorkflowPlay,
    element: <Route component={ProductWorkflowPlayground} />,
  },
  {
    path: ROUTES.brandClaims,
    element: <Route component={BrandClaimDashboard} />,
  },
  {
    path: ROUTES.glnClaims,
    element: (
      <Route
        component={GLNClaimDashboard}
        accessPolicy={glnDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.referentialPictures,
    element: <Route component={ReferentialPicturesDashboard} />,
  },
  {
    path: ROUTES.servicesDiscover,
    element: <Route component={DiscoverServives} />,
  },
  {
    path: ROUTES.rabbitmq,
    element: (
      <Route
        component={Rabbitmq}
        accessPolicy={rabbitmqDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.indexerQueue,
    element: (
      <Route
        component={IndexingQueue}
        accessPolicy={indexerDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.indexerConfig,
    element: <Route component={PumpConfiguration} />,
  },
  {
    path: ROUTES.kronosRevisions,
    element: (
      <Route
        component={RevisionList}
        accessPolicy={kronosDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.edaRmqReplayer,
    element: (
      <Route
        component={EdaRmqReplayer}
        accessPolicy={rabbitmqDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.edaRmqExplorer,
    element: (
      <Route
        component={EdaRmqExplorer}
        accessPolicy={rabbitmqDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.edaEventCaptures,
    element: (
      <Route
        component={EdaEventCapturesModule}
        accessPolicy={edaEventCapturesAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.edaCapturedEventsGraph,
    element: (
      <Route
        component={EdaCapturedEventsGraphModule}
        accessPolicy={edaEventCapturesAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.edaCapturedEvents,
    element: (
      <Route
        component={EdaCapturedEventsModule}
        accessPolicy={edaEventCapturesAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.edaRmqViewer,
    element: (
      <Route
        component={EdaRmqEventViewer}
        accessPolicy={rabbitmqDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.fieldsPage,
    element: (
      <Route component={FieldsPage} accessPolicy={fieldDashboardAccessPolicy} />
    ),
  },
  {
    path: ROUTES.organizationGroups,
    element: (
      <Route
        component={OrganizationGroups}
        accessPolicy={organizationGroupDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.organizationGroupField,
    element: (
      <Route
        component={OrganizationFieldGroupsModule}
        accessPolicy={organizationGroupFieldDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.organizationGroupFieldRoot,
    element: (
      <Route
        component={OrganizationFieldGroupsModule}
        accessPolicy={organizationGroupFieldDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.settings,
    element: (
      <Route
        component={SettingList}
        accessPolicy={settingsDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.setting,
    element: (
      <Route
        component={SettingEdit}
        accessPolicy={settingsDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.accessPolicy,
    element: (
      <Route
        component={AccessPolicy}
        accessPolicy={accessPolicyDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.accessPolicyEdit,
    element: (
      <Route
        component={AccessPolicyEdit}
        accessPolicy={accessPolicyDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.productMaintenance,
    element: (
      <Route
        component={ProductMaintenance}
        accessPolicy={productMaintenanceDashboardAccessPolicy}
      />
    ),
  },
  {
    path: ROUTES.productReview,
    element: <Route component={ProductReview} />,
  },
  {
    path: ROUTES.translation,
    element: (
      <Route
        component={TranslationDashboardView}
        accessPolicy={translationDashboardAccessPolicy}
      />
    ),
  },
  {
    path: 'admin/*',
    element: <RedirectLegacyRoute />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
].map((route) => {
  if (route.path.startsWith('/')) {
    route.path = route.path.substring(1);
  }
  return route;
});

const router = createBrowserRouter([
  {
    path: '/*',
    element: <Root />,
    children: routes,
  },
]);

export const AppRouter = memo(() => {
  useLayoutEffect(() => {
    /**
     * @param {MessageEvent<{ type: string; to: string; replace?: boolean }>} event
     */
    const callback = (event) => {
      if (
        event.origin === window.location.origin &&
        event.data?.type === NAVIGATE_EVENT
      ) {
        router.navigate(event.data.to, { replace: event.data.replace });
      }
    };
    window.addEventListener('message', callback);
    return () => {
      window.removeEventListener('message', callback);
    };
  }, []);

  useEffect(() => {
    Authentication.addLogoutListener(() => {
      router.navigate(logout);
    });
  }, []);

  return (
    <Suspense
      fallback={
        <div style={{ paddingTop: '100px', textAlign: 'center' }}>
          <Spinner loading big />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
});

function RedirectLegacyRoute() {
  const location = useLocation();
  return (
    <Navigate
      to={location.pathname.replace(/^\/admin/, '').trim() || ROUTES.home}
    />
  );
}

function PageNotFound() {
  return (
    <h1 style={{ paddingTop: '25vh' }}>
      <div style={{ textAlign: 'center' }}>
        <p>404 not found</p>
        <Link to="/">go back</Link>
      </div>
    </h1>
  );
}

function Root() {
  const [, setSearchParams] = useSearchParams();
  useLayoutEffect(() => {
    /**
     * @param {MessageEvent<{ type: string; queries: { [key: string]: any } }>} event
     */
    const callback = (event) => {
      if (
        event.origin === window.location.origin &&
        event.data?.type === 'ROOT_APP_UPDATE_QUERY_PARAMS'
      ) {
        setSearchParams(new URLSearchParams(event.data.queries));
      }
    };
    window.addEventListener('message', callback);
    return () => {
      window.removeEventListener('message', callback);
    };
  }, [setSearchParams]);
  return <Outlet />;
}
