export const dates = [
  {
    name: 'start_date',
    value: undefined,
    label: 'Date from',
  },
  {
    name: 'end_date',
    value: undefined,
    label: 'Date to',
  },
];
