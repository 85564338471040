import './Button.scss';

interface ButtonProps {
  onClick: () => void;
  label: string;
  fullWidth?: boolean;
}

export const Button = ({ onClick, label, fullWidth }: ButtonProps) => {
  return (
    <button
      className="UIButton"
      onClick={onClick}
      style={{ width: fullWidth ? '100%' : 'fit-content' }}
    >
      {label}
    </button>
  );
};
